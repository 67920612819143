import bridge from './bridge.json'
import convertor from './convertor.json'
import common from './common.json'
import device from './device.json'
import deviceGroup from './deviceGroup.json'
import egro from './egro.json'
import exports from './exports.json'
import gateway from './gateway.json'
import sensor from './sensor.json'
import connection from './connection'
import dashboard from './dashboard'
import desktop from './desktop'
import deviceSettings from './deviceSettings.json'
import feedback from './feedback.json'
import graph from './graph.json'
import handheld from './handheld.json'
import menu from './menu.json'
import onboarding from './onboarding.json'
import settings from './settings.json'
import support from './support.json'
import terms from './terms'
import auth from './auth.json'
import conditions from './conditions.json'
import header from './header.json'

export default {
  translation: {
    bridge,
    convertor,
    common,
    device,
    deviceGroup,
    egro,
    exports,
    gateway,
    sensor,
    connection,
    dashboard,
    desktop,
    deviceSettings,
    feedback,
    graph,
    handheld,
    menu,
    onboarding,
    settings,
    support,
    terms,
    auth,
    conditions,
    header,
  },
}
